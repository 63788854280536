<template>
  <div>
    <el-card>
      <nav class="input" style="width: auto">
        <el-input v-model="search.keyword" size="medium" placeholder="标签名称" />
      </nav>
      <nav class="input" style="width: auto">
        <el-select v-model="search.type" size="medium" placeholder="类型">
          <el-option label="全部" :value="0"></el-option>
          <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </nav>
      <nav class="input" style="width: auto">
        <el-select v-model="search.business" size="medium" placeholder="行业">
          <el-option label="全部" :value="0"></el-option>
          <el-option
              v-for="item in businessList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </nav>
<!--      <nav class="input" style="width: auto">-->
<!--        <el-select v-model="search.subBusiness" size="medium" placeholder="二级行业">-->
<!--          <el-option label="全部" :value="0"></el-option>-->
<!--          <el-option-->
<!--              v-for="item in subBusinessList"-->
<!--              :key="item.id"-->
<!--              :label="item.name"-->
<!--              :value="item.id"-->
<!--          >-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </nav>-->
      <nav class="input" style="width: 150px">
        <el-button size="small" @click="reset">重置</el-button>
        <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
      </nav>
      <nav class="input" style="width: auto">
        <el-button type="primary" size="small" @click="add">{{
            '新建标签'
          }}</el-button>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
          :data="list"
          style="width: 100%"
          border
          stripe
          :header-cell-style="rowClass"
          @sort-change="sortChange"
      >
        <el-table-column prop="id" label="ID" width="100px">
        </el-table-column>
        <el-table-column prop="name" label="行业标签" >
          <template slot-scope="{row}">
<el-tag type="primary">{{row.name}}</el-tag>
          </template>
        </el-table-column>>
        <el-table-column prop="typeName" label="类型" />
        <el-table-column prop="businessName" label="行业" />
<!--        <el-table-column prop="subBusinessName" label="二级行业" />-->
        <el-table-column prop="el_id" label="排序值"   sortable="custom">
          <template #header>
            <span>排序</span>
            <br />
<!--            <span  style="font-size:12px">（当前最大值：{{ orderMax }}）</span>-->
            <el-tooltip effect="dark" placement="top">
              <div slot="content">数值越大，显示越靠前</div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;" />
            </el-tooltip>
          </template>
          <template slot-scope="{row}">
            <orderNum @getlist="getlist" :permissions="true" :row="row" :val="{url:'/admin/EnterpriseLabel/editLabel'}"></orderNum>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
                type="text"
                icon="el-icon-edit"
                size="mini"
                style="color:skyblue"
                @click="editor(row)"
            >编辑</el-button>
            <!-- <el-button
              type="text"
              icon="el-icon-share"
              size="mini"
              style="color:tan"
              @click="toReceive(row)"
              >领取优惠券详情</el-button
            >

            </el-button> -->
            <el-button
                type="text"
                icon="el-icon-delete"
                size="mini"
                style="color:red"
                @click="deletes(row)"
            >删除</el-button
            >
            <!-- <el-button
              type="text"
              icon="el-icon-s-promotion"
              size="mini"
              style=" color: pink;"
              @click="shelves(row)"
              >{{ row.status == 0 ? '停用' : '启用' }}</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="search.page"
          background
          :page-sizes="[3, 5, 10, 15]"
          :page-size="search.pageSize"
          layout="total, prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
      />
    </el-card>
    <Drawers ref="drawer" :title="title" @getlist="getlist"></Drawers>
  </div>
</template>

<script>
import list from '/src/mixin/list.js'
import fuzzySelection from '/src/components/fuzzySelection'
import orderNum from '/src/components/remark/orderNum'
import { mapState } from 'vuex'
export default {
  mixins: [list],
  components: {
    fuzzySelection,
    orderNum,
  },
  data() {
    return {
      search: {
        keyword: '',
        page: 1,
        pageSize: 10,
        orderNum: '',
        type: '',
        business: '',
        // subBusiness:''
      },
      subBusinessList:[],
      orderMax: 0,
      title: '新增分类',
      typeList: [],
      businessList: [],
      form: {
        keyword: '',
        page: 1,
        pageSize: 10,
        orderNum: '',
        type: '',
        business: '',
        // subBusiness:''
      },
    }
  },
  watch: {
    "search.type"(newValue, oldValue) {
      this.getTypeLabel("1");
    },
    "search.business"(newValue, oldValue) {
      // this.getTypeLabel("2");
    },
  },
  created() {
    this.getlist()
    this.getType()
    this.getTypeLabel()
  },
  methods: {
    add() {
      //   this.$router.push('/enterprise_category_add')
      this.title = '新增标签'
      this.$refs.drawer.editId = null
      this.$refs.drawer.init(12)
      this.$refs.drawer.businessList = []
      this.$refs.drawer.ruleForm = {
        typeId: '',
        businessId: '',
        name: '',
      }
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.getlist()
      this.getType()
    },
    sortChange(val) {
      //
      // console.log(val.order.slice(0, val.order.length - 6),val);
      this.search.order =
          val.prop + ' ' + val.order.slice(0, val.order.length - 6)
      this.getlist()
    },
    toList(row) {
      this.$router.push({
        path: '/coupon_add',
        query: {
          id: row.id,
        },
      })
    },
    toReceive(row) {
      this.$router.push({
        path: '/coupon_detail',
        query: {
          id: row.id,
        },
      })
    },
    async editor(row) {
      this.title = '编辑标签'
      this.$refs.drawer.labelForm.id = row.id
      this.$refs.drawer.init(12)
    },
    async getTypeLabel(val) {
      if (val == 1) {
        var { data: res } = await this.$http.get(
            `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.search.type}&businessId=`
        );
        this.businessList = res.data.list;
        return;
      }
      if (val == 2) {
        var { data: res } = await this.$http.get(
            `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.search.type}&businessId=${this.search.business}`
        );
        this.subBusinessList = res.data.list;
        return;
      }
      var { data: res } = await this.$http.get(
          "/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId="
      );
      this.typeList = res.data.list;
    },
    async deletes(row) {
      try {
        await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        let { data: res } = await this.$http.get(
            '/admin/EnterpriseLabel/deleteLabel?id=',
            {
              params: {
                id: row.id,
              },
            }
        )
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          if (this.total % 10 == 1) {
            this.search.page--
          }
          this.getlist()
        }
      } catch (error) {
        this.$message({
          type: 'info',
          message: '已取消删除',
        })
      }
    },
    async shelves(row) {
      // console.log(row.status);
      const { data: res } = await this.$http.post(
          '/admin/Coupon/updateStatus',
          {
            id: row.id,
            status: row.status ? 0 : 1,
          }
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getlist()
      }
    },
    handleCurrentChange(e) {
      this.search.page = e
      this.form.page = e
      //   console.log(this.form);
      this.getlist(this.form)
    },
    async getType() {
      var { data: res } = await this.$http.get(
          '/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId='
      )
      this.typeList = res.data.list
      this.$refs.drawer.typeList = res.data.list
    },
    async getlist(val) {
      const form = val || this.search
      let { data: res } = await this.$http.get(
          '/admin/EnterpriseLabel/getList',
          {
            params: {
              ...form,
            },
          }
      )
      this.list = res.data.list
      this.total = res.data.totalCount
      this.orderMax = res.data.orderMax
    },
    subimt() {
      this.search.page = 1
      this.form = {
        ...this.search,
      }

      this.getlist()
    },
  },
  computed: {
    ...mapState({
      btn_if: (state) => state.btn_if,
    }),
    typeList() {
      return this.$refs.drawer.typeList
    },
  },
}
</script>

<style lang="less" scoped>
.click {
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
}
</style>
